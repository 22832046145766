import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PitchRouter from "./PitchRouter";
import { AppContext } from "./libs/contextLib";
import logger from "./libs/errorLib";
import {authenticateUser, signOutUser} from "./api/auth_api";
import "./styles/_text.scss";
import "./styles/forms.scss";
import ErrorBoundary from "./components/error/ErrorBoundary";
import ErrorManager from "./components/error/ErrorManager";
import InfoManager from "./components/info/InfoManager";
import { PitchNoCurrentUserError } from "./libs/shared/exceptions";
import { useOrganisation, usePermissions, useUser } from "./libs/apiHooksLib";
import { useScript } from "./libs/hooksLib";
import { Organisation, OrganisationUser, Permission } from "./libs/shared/model";
import { getActiveOrgId, getOverrideOrgId, isOrgAccountActive, isOverrideOrgActive, removeActiveOrgId } from "./api/localstorage_api";
import OrganisationSelectField from "./components/forms/fields/OrganisationSelectField";
import PitchliPage from "./components/layout/PitchliPage";
import AppNav from "./components/layout/AppNav";
import { getAppStage } from "./libs/shared/app-config";

function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [showLoginNav, setShowLoginNav] = useState(true);
  const {organisation, setOrganisation, setOrganisationById} = useOrganisation();
  const {user, setUser} = useUser();
  const {loadPermissions, getPermission} = usePermissions();
  const [joiningLink, setJoiningLink] = useState();

  const navigate = useNavigate();

  useScript("https://www.bugherd.com/sidebarv2.js?apikey=oviy2qypexhfjrkh4tjjig", getAppStage() !== "prod");

  useEffect(() => {
    onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    try {
      let orgId = null;
      let userOrgId = null;
      if(isOrgAccountActive()){
        userOrgId = getActiveOrgId();
        orgId = isOverrideOrgActive() ? getOverrideOrgId() : userOrgId;
        try{
          await setOrganisationById(orgId);
        }catch(e){
          logger.error(e);
          removeActiveOrgId();
        }
      }
      const user = await authenticateUser(userOrgId);
      setUser(user);
      userHasAuthenticated(true);
    } catch(e) {
      if (!(e instanceof PitchNoCurrentUserError)){
        logger.error(e);
      }
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    async function loadPerms(){
      try{
        await loadPermissions(organisation ? organisation.orgId : null);
      }catch(e){
        logger.error(e);
      }
    }
    //TODO update to also allow non Org Users perms loading
    if(user && user instanceof OrganisationUser) loadPerms();
  }, [loadPermissions, organisation, user]);

  async function handleLogout() {
    try{
      await signOutUser();
      logger.debug("User signed out");
      logger.debug("User set to unauthenticated, pushing home route");
    }catch(e){
      logger.error("Error signing out: " + e.message);
    }finally{
      userHasAuthenticated(false);
      navigate("/");
      setUser(null);
      setOrganisation(null);
    }
  }

  return (
    !isAuthenticating && (
      <>
        <PitchliPage
          navComponent={<AppNav
            isAuthenticated={isAuthenticated}
            isOrganisation={!!organisation}
            orgId={organisation?.orgId}
            organisationName={organisation?.organisationName}
            overrideOrgId={getOverrideOrgId()}
            activeOrgId={getActiveOrgId()}
            isAdmin={organisation ? getPermission(Organisation.generateKey(organisation.orgId), Permission.permissionTypes.ADMIN) : false}
            OrganisationSelectField={<OrganisationSelectField />}
            handleLogout={handleLogout}
            showLoginNav={showLoginNav} />}
          >
          <ErrorBoundary>
            <ErrorManager>
              <InfoManager>
                <AppContext.Provider value={{ isAuthenticated, isAuthenticating, userHasAuthenticated, joiningLink, setJoiningLink, setShowLoginNav }}>
                  <PitchRouter/>
                </AppContext.Provider>
              </InfoManager>
            </ErrorManager>
          </ErrorBoundary>
        </PitchliPage>
      </>
    )
  );
}

export default App;