import React from "react";
import {Modal} from "react-bootstrap";
import { PitchUserError, PitchNoPermissionError } from "../../libs/shared/exceptions";
import { isForceShowError, isDev } from "../../libs/sentryLib";
import PitchButton from "../buttons/PitchButton";

export default function ErrorModal({error, userAckFnc}) {

    return (
        <Modal
            show={error!==null}
            onHide={userAckFnc}
            backdrop="static"
            keyboard={false}
        >
            {error && (
                <>
                <Modal.Header className={(error instanceof PitchUserError) ? "container-secondary-1-0" : "container-primary-0"} closeButton>
                    <Modal.Title>{(error instanceof PitchUserError) ? "Whoops!" : "Error"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(error instanceof PitchNoPermissionError && !isDev()) ? (
                        "Sorry, you don't have permission to complete that action"
                    ) : (error instanceof PitchUserError || isDev() || isForceShowError()) ? (
                        error.message
                    ) : (
                        "We're sorry, but there's been an error. Please refresh this page and try again."
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <PitchButton variant="secondary" onClick={userAckFnc}>
                        Close
                    </PitchButton>
                </Modal.Footer>
                </>
            )}
        </Modal>
    );
}