import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { createStripePortalSession, getOrganisation, getUserPermissions, logAnonMetrics, logAnonView, logDecision, logView, updateInteraction } from "../api/lambda_api";
import { storeAnonView } from "../api/localstorage_api";
import { useErrorContext, useInfoContext } from "./contextLib";
import logger from "./errorLib";

//https://stackoverflow.com/questions/53451584/is-it-possible-to-share-states-between-components-using-the-usestate-hook-in-r
function makeObservable(target) {
  let listeners = [];
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  return {
    get,
    set,
    subscribe,
  };
}

const permissionsStore = makeObservable();

export function usePermissions() {
  const [permissionsState, setPermissionsState] = useState(permissionsStore.get());

  useEffect(() => {
    return permissionsStore.subscribe(setPermissionsState);
  }, []);

  const getPermission = useCallback((Key, permission) => {
    if(permissionsState){
      const filteredPermissions = permissionsState.filter(perm => (perm.isWildcard() || (perm.getEntityKey().PK === Key.PK && perm.getEntityKey().SK === Key.SK)) && perm.permission >= permission);
      filteredPermissions.sort((perm1, perm2) => perm2.permission - perm1.permission);
      return filteredPermissions[0];
    }
    return null;
  },[permissionsState]);

  const setPermissions = (permissions) => {
    permissionsStore.set(permissions);
  }

  const loadPermissions = useCallback(async(orgId) => {
    const permissions = await getUserPermissions(orgId);
    setPermissions(permissions.items);
  },[]);

  return {
    permissions: permissionsState,
    getPermission,
    setPermissions,
    loadPermissions
  }
}

const organisationStore = makeObservable();

export function useOrganisation() {
  const [organisation, setOrgState] = useState(organisationStore.get());

  useEffect(() => {
    return organisationStore.subscribe(setOrgState);
  }, []);

  const setOrganisation = (org) => {
    organisationStore.set(org);
  }

  const setOrganisationById = async(orgId) => {
    const org = await getOrganisation(orgId);
    organisationStore.set(org);
  }

  const reloadOrganisation = async() => {
    const orgReloaded = await getOrganisation(organisation.orgId);
    organisationStore.set(orgReloaded);
  }

  return {
    organisation,
    setOrganisation,
    setOrganisationById,
    reloadOrganisation
  }
}

const userStore = makeObservable();

export function useUser() {
  const [user, setUserState] = useState(userStore.get());

  useEffect(() => {
    return userStore.subscribe(setUserState);
  }, []);

  const setUser = (user) => {
    userStore.set(user);
  }

  return {
    user,
    setUser
  }
}

export function useLogView(user, itemKey, orgId, start = true) {

  const [isStarted, setIsStarted] = useState(false);
  const [view, setView] = useState(null);

  const startView = useCallback(async(initialMetrics = {}) => {
    if(itemKey){
      logger.debug("Starting view");
      setIsStarted(true);
      if(user){
        const viewUpdated = await logView(user, itemKey, initialMetrics, orgId);
        setView(viewUpdated);
      }else{
        const firstView = storeAnonView(itemKey);
        await logAnonView(itemKey, initialMetrics, firstView);
        setView({});
      }
    }
  },[itemKey, orgId, user]);

  const endView = () => {
    setIsStarted(false);
  }

  useEffect(() => {
    if(start && !isStarted && itemKey) startView();
  },[itemKey, start, isStarted, startView]);

  const incrementMetrics = async(metrics) => {
    if(user){
      //Mark clean in case any state changes are still processing.
      view.markClean();
      view.changeCounts(metrics, true);
      const viewUpdated = await updateInteraction(view, orgId);
      setView(viewUpdated);
    }else{
      await logAnonMetrics(itemKey, metrics);
      setView({});
    }
  }
  return {
    startView,
    endView,
    view,
    incrementMetrics
  }
}

export function useLogDecision(user, itemKey, viewerOrgId) {

  const [decision, setDecision] = useState(null);

  const makeDecision = useCallback(async(decision) => {
    logger.debug("Logging decision");
    if(itemKey && user){
      const intUpdated = await logDecision(viewerOrgId, user, itemKey, decision);
      setDecision(intUpdated);
    }
  },[itemKey, viewerOrgId, user]);

  return {
    makeDecision,
    decision
  }
}

export function useRedirectToSubscriptions(){
  const {setUserMessage} = useInfoContext();
  const {setError} = useErrorContext();
  const {organisation} = useOrganisation();
  const navigate = useNavigate();

  async function redirect(e){
    if(e) e.preventDefault();
    setUserMessage({
        title: "Subscription limit reached",
        body: "You've reached the limit of your subscription. To perform this action increase your subscription level.",
        buttonText: (organisation.hasActiveSubscription() ? "Manage subscription" : "Upgrade susbcription"),
        cancelButtonText: "Cancel",
        callback: async() => {
          if(!organisation.hasActiveSubscription() || organisation.subscription.status === 'free') {
            navigate('/select_subscription');
          }else{
            try{
              const completionPath = "org_settings";
              const session = await createStripePortalSession(organisation.subscription.customerId, completionPath);
              window.location.assign(session.stripeUrl);
            }catch(e){
              setError(e);
            }
          }
        }
    });
  }
  return {
    redirect
  };
}