import React from "react";
import PitchliFooter from "./PitchliFooter";
import PitchliNavBar from "./PitchliNavBar";

export default function PitchliPage({
    navComponent,
    LinkComponent,
    children
}){
    return(
        <>
            <PitchliNavBar
                navComponent={navComponent} 
                LinkComponent={LinkComponent}/>
            <div className="full-height-container px-0 mx-auto pt-4">
                {children}
            </div>
            <PitchliFooter />
        </>
    )
}