import {common, dev_shared, staging_shared, prod_shared} from "./config.js";

const dev_app = {
  LEGAL_TERMS_VERSION: "1.0",
  PRIVACY_TERMS_VERSION: "1.0",
  apiGateway: {
      REGION: "ap-southeast-2",
      URL: "https://07rurm7d50.execute-api.ap-southeast-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_U8sdgAuiC",
    APP_CLIENT_ID: "3bglf88qul3d9eu50kgf6he1e7",
    IDENTITY_POOL_ID: "ap-southeast-2:76713faf-c977-49ff-88bf-774b4a908a35"
  },
  pinpoint: {
    APP_ID: "90f4db4fcba04d39be3e14fbb617ebbd",
    REGION: "ap-southeast-2"
  }
}

const staging_app = {
  LEGAL_TERMS_VERSION: "1.0",
  PRIVACY_TERMS_VERSION: "1.0",
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://v8fj0v8a8f.execute-api.ap-southeast-2.amazonaws.com/staging"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_P5WHKuW1Y",
    APP_CLIENT_ID: "evvt47o1tdhga7d2afldh3nbu",
    IDENTITY_POOL_ID: "ap-southeast-2:88b9e6fe-eafb-437e-a99d-785a1cbc473b"
  },
  pinpoint: {
    APP_ID: "8d91b343816d4cbebef51c33c2d7e9ba",
    REGION: "ap-southeast-2"
  }
};

const prod_app = {
  LEGAL_TERMS_VERSION: "1.0",
  PRIVACY_TERMS_VERSION: "1.0",
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://8lyblwalsk.execute-api.ap-southeast-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_ql2OzUxyK",
    APP_CLIENT_ID: "7n27a95q0td7727t0g074m7fel",
    IDENTITY_POOL_ID: "ap-southeast-2:38b740b1-e77e-43fb-bb3e-6ce1ea30d32a"
  },
  pinpoint: {
    APP_ID: "00975f97f6f842c1a1d61eed20f1c198",
    REGION: "ap-southeast-2"
  }
};

export const getAppConfig = (stage) => ({
    ...common,
  ...(stage === "prod" ? prod_shared : stage === "staging" ? staging_shared : dev_shared),
  ...(stage === "prod" ? prod_app : stage === "staging" ? staging_app : dev_app)
});

export const getSharedConfig = (stage) => ({
    ...common,
    ...(stage === "prod" ? prod_shared : stage === "staging" ? staging_shared : dev_shared)
})

export const getAppStage = () => process.env.GATSBY_REACT_APP_STAGE ? process.env.GATSBY_REACT_APP_STAGE : process.env.REACT_APP_STAGE;

export const appConfig = getAppConfig(getAppStage());