import { useEffect, useState } from "react";
import { Badge, Container, Nav } from "react-bootstrap";
import { useLocation } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import SignInButtons from "../buttons/SignInButtons";
import queryString from "query-string";

export default function AppNav({organisationName, orgId, isAuthenticated, overrideOrgId, activeOrgId, isOrganisation, isAdmin, OrganisationSelectField, handleLogout, showLoginNav}){

    const [qString, setQString] = useState();
    const location = useLocation();

    useEffect(() => {
      const queryObject = queryString.parse(location.search);
      if(!queryObject.step) queryObject.step = 0;
      let qStr = queryString.stringify(queryObject);
      if(qStr.length > 0) qStr = "?" + qStr;
      setQString(qStr);
    }, [location.search]);

    const parseOrganisationToPicklistObject = (org) => {
        if(org){
            return {
                value: {
                    display: org.organisationName,
                    orgId: org.orgId,
                },
                label: org.organisationName
            }
        }else{
            return {}
        }
    }

    return (
        <Nav activeKey={window.location.pathname + qString}>
            {isAuthenticated ? (
                <>
                {overrideOrgId && activeOrgId !== overrideOrgId && (
                    <Container><Badge variant="complement">Viewing another account</Badge></Container>
                )}
                {isOrganisation && isAdmin && (
                    <OrganisationSelectField initialValue={parseOrganisationToPicklistObject({organisationName, orgId})}/>
                )}
                {!isOrganisation && (
                    <LinkContainer to="/settings">
                    <Nav.Link className="text-navy">Settings</Nav.Link>
                    </LinkContainer>
                )}
                {isOrganisation && (
                    <LinkContainer to="/org_settings">
                    <Nav.Link className="text-navy">Settings</Nav.Link>
                    </LinkContainer>
                )}
                <Nav.Link onClick={handleLogout} className="text-navy">Logout</Nav.Link>
                </>
            ) : (
                showLoginNav && (
                    <>
                    <LinkContainer to="/pricing" className="mt-2 mx-2">
                        <Nav.Link className="text-navy">Pricing</Nav.Link>
                    </LinkContainer>
                    <SignInButtons linkClassName="m-2" relativePaths={true}/>
                    </>
                )
            )}
        </Nav>
    )
}