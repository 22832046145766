import { useState, useEffect, useRef, useCallback } from "react";
import { Organisation, User } from "../libs/shared/model";
import logger from "./errorLib";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}

const bootstrapSizing = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export function compareBootstrapSizes(bs1, bs2){
  const idx1 = Object.keys(bootstrapSizing).indexOf(bs1);
  const idx2 = Object.keys(bootstrapSizing).indexOf(bs2);
  if(idx1 > idx2 || (idx2 === -1 && idx1 !== -1)) return 1;
  if(idx1 === idx2) return 0;
  if(idx1 < idx2 || (idx1 === -1 && idx2 !== -1)) return -1;
}

function useWindowDimensions() {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [bootstrapSize, setBootstrapSize] = useState();

  useEffect(() => {
    if(window){
      let w = window.innerWidth;
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      let bsSize = "xs";
      if(w >= bootstrapSizing.xl){
        bsSize = "xl";
      }else if(w >= bootstrapSizing.lg){
        bsSize = "lg"
      }else if(w >= bootstrapSizing.md){
        bsSize = "md";
      }else if(w >= bootstrapSizing.sm){
        bsSize = "sm"
      }
      setBootstrapSize(bsSize);
    }
  },[]);

  return {
    width,
    height,
    bootstrapSize
  };
}

function getDimensions(ref){
  return {
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight
  };
}

export function useDimensions(ref, dependencies = null) {
  const windowDimensions = useWindowDimensions();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function handleResize() {
      if(ref) setDimensions(getDimensions(ref));
    }

    if (ref && ref.current) {
      setDimensions(getDimensions(ref));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  useEffect(() => {
    if(ref && ref.current){
      setDimensions(getDimensions(ref));
    }
  },[dependencies, ref]);

  return {
    window: windowDimensions,
    container: dimensions
  }
}

export function useCalculatedDimensions(componentRef, originalHeight, originalWidth, dependencies = null) {
  const {container, window} = useDimensions(componentRef, dependencies);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [maxHeight, setMaxHeight] = useState();
  const [maxWidth, setMaxWidth] = useState();

  const calculateDimensions = useCallback((originalWidth, originalHeight) => {
    const dimensions = {};
    const containerRatio = container.width / originalWidth;
    const bootstrapHeight = decideHeight(window.bootstrapSize);
    dimensions.maxHeight = bootstrapHeight;
    dimensions.maxWidth = bootstrapHeight * (originalWidth / originalHeight);
    if(originalHeight * containerRatio > bootstrapHeight){
        dimensions.height = dimensions.maxHeight;
        dimensions.width = dimensions.maxWidth;
    }else{
        dimensions.height = originalHeight * containerRatio;
        dimensions.width = dimensions.height * (originalWidth / originalHeight);
    }
    return dimensions;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, window.bootstrapSize]);

  useEffect(() => {
    const dimensions = calculateDimensions(originalWidth || container.width, originalHeight || container.height);
    setHeight(dimensions.height);
    setWidth(dimensions.width);
    setMaxHeight(dimensions.maxHeight);
    setMaxWidth(dimensions.maxWidth);
  },[container, calculateDimensions, originalWidth, originalHeight, dependencies]);

  function decideHeight(bootstrapSize){
    switch (bootstrapSize){
        case "xs":
            return 400;
        default:
            return decideWidth(bootstrapSize) / 1.777777777;
    }
  }

  function decideWidth(bootstrapSize){
    switch (bootstrapSize){
        case "xxl":
            return 1320;
        case "xl":
            return 1140;
        case "lg":
            return 960;
        case "md":
            return 720;
        case "sm":
            return 540;
        case "xs":
            return 0;
        default:
            return 0;
    }
  }

  return {
    height,
    width,
    maxHeight,
    maxWidth,
    calculateDimensions
  }
}

export function useScrollStatus(){
  const [scrollStarted, setScrollStarted] = useState(false);
  const [bottomScrollLimitReached, setBottomScrollLimitReached] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    function handleScroll(event) {
      if(!scrollStarted) setScrollStarted(true);
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      setScrollY(window.scrollY);
      setBottomScrollLimitReached(bottom);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return {
    bottom: bottomScrollLimitReached,
    scrollY,
    scrollStarted
  }
}

//https://stackoverflow.com/questions/58341787/intersectionobserver-with-react-hooks/67826055#67826055
export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export function isUserSignUpComplete(authenticated, user){
  if(!authenticated) return false;
  if(!user) return false;
  if(!user.status) return false;
  switch (user.status){
      case User.userRegistrationStatus.UNCONFIRMED:
      case User.userRegistrationStatus.CONFIRMED:
          return false;
      case User.userRegistrationStatus.COMPLETED:
          return true;
      default:
          return false;
  }
}

export function isOrganisationSignUpComplete(authenticated, user, org){
  if(!authenticated) return false;
  if(!user || !org) return false;
  if(!user.status || !org.status) return false;
  switch (user.status){
      case User.userRegistrationStatus.UNCONFIRMED:
      case User.userRegistrationStatus.CONFIRMED:
          return false;
      case User.userRegistrationStatus.COMPLETED:
          return org.status === Organisation.organisationRegistrationStatus.COMPLETED;
      default:
          return false;
  }
}

export function getFullUrl(path, queryString){
  const host = window.location.host;
  const protocol = window.location.protocol;
  let url = protocol + "//" + host + "/" + path;
  url = queryString ? url + "?" + queryString : url;
  return url;
}

export function useInterval(callback, delay, id = ""){
  const savedCallback = useRef();
  const [count, setCount] = useState(0);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick(){
      savedCallback.current();
      setCount(prevCount => prevCount + 1);
    }
    if(delay !== null){
      const id = setInterval(tick, delay)
      logger.debug("Interval set with id: " + id);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);

  return {
    ["count" + id]: count
  }
}

export function useScript(url, condition = true){
  useEffect(() => {
    let script;
    if(condition){
      script = document.createElement('script');

      script.src = url;
      script.async = true;

      document.head.appendChild(script);
    }

    return () => {
      if(script) document.head.removeChild(script);
    }
  }, [url, condition]);
};

export function usePageTitle(title){
  useEffect(() => {
      document.title = (title && title !== "title") ? title + " | Pitchli" : "Pitchli";
  }, [title]);
}

export function useLocationEffect(){
  const [location, setLocation] = useState({});

  useEffect(() => {
    if(window && window.location) setLocation(window.location);
  },[]);

  return location;

}