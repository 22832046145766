import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function PitchliFooter(){
    return(
    <Container className="container-navy full-width-container justify-content-center">
        <Container xs={12} className="p-3">
        <Row className="px-3">
            <Col sm={4}>
            <ul className="list-style-none">
                <li className="py-1"><span className="text-fineprint">&copy; Pitchli 2021</span></li>
            </ul>
            </Col>
            <Col sm={4}>
            <ul className="list-style-none">
                <li className="py-1"><a href="mailto:support@pitchli.com?subject=Pitchli%20help%20please" className="text-fineprint link-white">Contact support</a></li>
            </ul>
            </Col>
            <Col sm={4}>
            <ul className="list-style-none">
                <li className="py-1"><a href="/legal" className="text-fineprint link-white">Terms &amp; conditions</a></li>
                <li className="py-1"><a href="/privacy" className="text-fineprint link-white">Privacy policy</a></li>
            </ul>
            </Col>
        </Row>
        </Container>
    </Container>
    );
}