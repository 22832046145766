import React from "react";
import { Alert } from "react-bootstrap";

export default function InfoAlert({message, variant, alertDismissedFunction, ...props}){
    return(
        <>
            {message ? (
                <Alert variant={variant} dismissible onClose={alertDismissedFunction} {...props}>
                    {message}
                </Alert>
                ) : null
            }
        </>
    )
}