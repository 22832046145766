import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PitchButton from "./PitchButton";
import {appConfig} from '../../libs/shared/app-config';
import { useLocationEffect } from "../../libs/hooksLib";

export default function SignInButtons({relativePaths = false, signInText = "Sign in", signUpText = "Try for free", linkClassName = "m-2"}){

    const location = useLocationEffect();
    const prefix = relativePaths ? "" : `https://${appConfig.domains.APP}`;

    return(
        <div className="navbar-nav">
            {relativePaths ? (
                <>
                    {signInText && (<LinkContainer to={prefix + "/login_choice" + location.search} className={linkClassName}>
                        <Nav.Link as={PitchButton} variant="outline-complement">{signInText}</Nav.Link>
                    </LinkContainer>)}
                    {signUpText && (<LinkContainer to={prefix + "/org_signup" + location.search} className={linkClassName}>
                        <Nav.Link as={PitchButton} variant="primary">{signUpText}</Nav.Link>
                    </LinkContainer>)}
                </>
            ) : (
                <>
                    {signInText && (<PitchButton href={prefix + "/login_choice" + location.search} variant="outline-complement" className={linkClassName + " nav-link"}>
                        {signInText}
                    </PitchButton>)}
                    {signUpText && (<PitchButton href={prefix + "/org_signup" + location.search} variant="primary" className={linkClassName + " nav-link"}>
                        {signUpText}
                    </PitchButton>)}
                </>
            )}
        </div>
    );
}