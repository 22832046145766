import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isOrgAccountActive } from "../../api/localstorage_api";
import { useAppContext } from "../../libs/contextLib";
import { isUserSignUpComplete, isOrganisationSignUpComplete, usePageTitle } from "../../libs/hooksLib";
import { useUser, useOrganisation } from "../../libs/apiHooksLib";

export default function HomeRoute({ unauthenticatedComponent, authenticatedComponent, showLoginNav = true, title = null, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();
  const {user} = useUser();
  const {organisation} = useOrganisation();

  usePageTitle(title);

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  if(isAuthenticated){
    if(isOrgAccountActive()){
      if(isOrganisationSignUpComplete(isAuthenticated, user, organisation)){
        return authenticatedComponent;
      }else{
        return <Navigate replace to={`/org_signup?redirect=${pathname}${search}`} />
      }
    }else{
      if(isUserSignUpComplete(isAuthenticated, user)){
        return authenticatedComponent;
      }else{
        return <Navigate replace to={`/signup?redirect=${pathname}${search}`} />
      }
    }
  }else{
    return unauthenticatedComponent;
  }
}