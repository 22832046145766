import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import queryString from "query-string";
import { isUserSignUpComplete, usePageTitle } from "../../libs/hooksLib";
import { useOrganisation, useUser } from "../../libs/apiHooksLib";

export default function SignupRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();
  const {user} = useUser();
  const {organisation} = useOrganisation();
  const location = useLocation();
  const redirect = queryString.parse(location.search).redirect;

  usePageTitle(title);

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  if(!isUserSignUpComplete(isAuthenticated, user, organisation)){
    return children;
  }else{
    return <Navigate replace to={redirect === "" || redirect === null || redirect === undefined ? "/" : redirect} />
  }
}