import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Pitchli from "../icons/Pitchli";

export default function PitchliNavBar({
    navComponent,
    LinkComponent = LinkContainer
}){

    return(
        <Navbar fixed="top" collapseOnSelect expand="md" className="container-white-0 box-shadow-1" bg="white">
            <Container fluid>
            <LinkComponent to="/" className="link-no-decoration">
                <Navbar.Brand>
                <Pitchli size={30} className="ms-2 me-0 p-0"/>{'   '}
                <span className="fw-bold text-navy pitchli-text">pitchli</span>
                </Navbar.Brand>
            </LinkComponent>
            <Navbar.Toggle aria-controls="navbar"/>
            <Navbar.Collapse id="navbar" className="justify-content-end">
                {navComponent}
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}